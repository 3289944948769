import useAxios from 'composables/useAxios';
import router, { getApiRoute } from 'routeconfig';
import { applyDefaultFilterValuesBasedOnQueryParams } from 'helper';

export const usePublisherDashboardFilters = () => {
  const {
    state: { loading: fetchingPageFilters, data: pageFilters },
    fetcher: filtersFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchPageFilters = () =>
    filtersFetcher({
      url: getApiRoute({
        name: 'api.publisher-dashboard.overview.filters'
      }),
      responseAdapter: data =>
        data?.filters
          ? applyDefaultFilterValuesBasedOnQueryParams({
              filters: data.filters,
              queryParams: router.currentRoute.value.query
            })
          : []
    });

  return {
    fetchPageFilters,
    fetchingPageFilters,
    pageFilters
  };
};
