export const publisherDashboardAdministration = [
  {
    path: 'publisher-dashboard',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'manage-tracking-identifiers',
        name: 'admin.publisher-dashboard.manage-tracking-identifiers',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/publisherDashboard/PublisherDashboardTrackingIdentifiersManagement.vue'
          ),
        meta: {
          auth: true,
          hasCountrySwitcher: true,
          module: 'publisher_dashboard_admin'
        }
      }
    ]
  }
];
