import { ref, computed } from 'vue';
import { parse as parseQuery } from 'qs';
import { useRoute } from 'vue-router';
import { useAxios, useRatingsItems } from 'composables';
import { getApiRoute } from 'routeconfig';
import { parseQueryValue, convertToArray } from 'utils/helpers';
import { FILTER_KEYS } from 'views/ratings/RatingsConstants';
import { getRatingsQueryParams } from 'views/ratings/helpers';
import { formatDataFrame } from 'utils/formatDataFrame';
import { translateFilters } from 'utils/translate';

export const currentDataFrame = ref();

const useExcludableServices = () => {
  const {
    fetchItems,
    items: excludableServices,
    itemsError: excludableServicesError,
    itemsLoading: excludableServicesLoading
  } = useRatingsItems();

  const fetchExcludableServices = ({ dataFrame = {} } = {}) =>
    fetchItems({
      extraQuery: dataFrame
    });

  return {
    fetchExcludableServices,
    excludableServices,
    excludableServicesLoading,
    excludableServicesError
  };
};

const useDataFrames = () => {
  const {
    state: { data: dataFrames, loading: dataFramesLoading, error: dataFramesError },
    fetcher: fetchDataFramesFetcher
  } = useAxios({
    initialValue: []
  });

  // audienceReach param is used to fetch filtered data-frames for Audience Reach chart select and results views
  const fetchDataFrames = ({ audienceReach }) =>
    fetchDataFramesFetcher({
      url: getApiRoute({
        name: 'api.ratings.data-frames',
        query: {
          ...(audienceReach ? { audience_reach: 1 } : {})
        }
      })
    });

  return {
    dataFrames,
    dataFramesLoading,
    dataFramesError,
    fetchDataFrames
  };
};

const useCategories = () => {
  const {
    state: { loading: categoriesLoading, data: categories, error: categoriesError },
    fetcher: fetchCategoriesFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchCategories = () =>
    fetchCategoriesFetcher({
      url: getApiRoute('api.ratings.categories')
    });

  return {
    categories,
    categoriesLoading,
    categoriesError,
    fetchCategories
  };
};

const useAttributeFilters = () => {
  const route = useRoute();
  const {
    state: { loading: attributeFiltersLoading, error: attributeFiltersError, data: attributeFilters },
    fetcher: fetchAttributeFiltersFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchAttributeFilters = ({ dataFrame } = {}) => {
    const parsedQuery = parseQuery(route.query, {
      arrayLimit: 0
    });

    return fetchAttributeFiltersFetcher({
      url: getApiRoute({
        name: 'api.ratings.attributes',
        query: getRatingsQueryParams({
          extraQuery: dataFrame
        })
      }),
      responseAdapter: attributes => {
        const DEFAULT_VISIBLE_ATTRIBUTE_KEYS = [
          'birthday',
          'gender',
          'province',
          'meta45_birthday',
          'meta45_gender',
          'meta45_province'
        ];

        return (attributes || []).map(({ id, name: label, values: options, key }) => ({
          id,
          key,
          label,
          options,
          visible:
            DEFAULT_VISIBLE_ATTRIBUTE_KEYS.includes(key) || !!parsedQuery[FILTER_KEYS.ATTRIBUTES]?.[id],
          removable: !DEFAULT_VISIBLE_ATTRIBUTE_KEYS.includes(key),
          value: convertToArray(
            parseQueryValue(Object.values(parsedQuery[FILTER_KEYS.ATTRIBUTES]?.[id] || {}))
          )
        }));
      }
    });
  };

  return {
    attributeFilters,
    attributeFiltersLoading,
    attributeFiltersError,
    fetchAttributeFilters
  };
};

const getCurrentDataFrame = ({ dataFrames = [], date, interval_type, calculation_type }) => {
  const defaultDataFrame = dataFrames[0];

  if (!date || !interval_type || !calculation_type) {
    return defaultDataFrame;
  }

  const dataFrame = dataFrames.find(
    dataFrame =>
      dataFrame.date === date &&
      dataFrame.interval_type === interval_type &&
      dataFrame.calculation_type === calculation_type
  );

  return dataFrame || defaultDataFrame;
};

export const useRatingsFilters = ({
  dataFrames = false,
  excludeServices = false,
  bookable = false,
  devices = false,
  categories = false,
  registeredOnlyStats = false,
  attributes = false,
  oewaUnitsOnly = false,
  audienceReach = false
} = {}) => {
  const loadingFilters = ref(false);

  const route = useRoute();
  const { fetchDataFrames, dataFrames: dataFramesRes, dataFramesLoading, dataFramesError } = useDataFrames();
  const { categoriesLoading, fetchCategories, categories: categoriesRes, categoriesError } = useCategories();
  const { attributeFiltersLoading, attributeFiltersError, fetchAttributeFilters, attributeFilters } =
    useAttributeFilters();
  const { excludableServicesLoading, fetchExcludableServices, excludableServicesError, excludableServices } =
    useExcludableServices();

  const parsedQuery = parseQuery(route.query);

  const filters = computed(() => {
    const result = [];

    if (dataFrames) {
      const { date, interval_type, calculation_type } = parsedQuery;

      result.push({
        key: FILTER_KEYS.DATA_FRAME,
        loading: dataFramesLoading.value,
        error: dataFramesError.value,
        label: translateFilters(`filter>${FILTER_KEYS.DATA_FRAME}`),
        options: (dataFramesRes.value || []).map(dataFrame => ({
          ...dataFrame,
          name: formatDataFrame({
            date: dataFrame.date,
            intervalType: dataFrame.interval_type
          })
        })),
        value: getCurrentDataFrame({
          date,
          interval_type,
          calculation_type,
          dataFrames: dataFramesRes.value
        })
      });

      if (excludeServices) {
        result.push({
          key: FILTER_KEYS.EXCLUDE_SERVICES,
          loading: excludableServicesLoading.value,
          error: excludableServicesError.value,
          label: 'Exclude users who visit',
          options: excludableServices.value,
          keyProp: 'key',
          value: convertToArray(parsedQuery[FILTER_KEYS.EXCLUDE_SERVICES]).flatMap(service => {
            const { id, type } = service || {};
            const key = `${id}_${type}`;

            const serviceExists = excludableServices.value.some(service => service.key === key);

            return serviceExists ? [key] : [];
          })
        });
      }

      if (attributes) {
        result.push({
          key: FILTER_KEYS.ATTRIBUTES,
          attributes: attributeFilters.value,
          loading: attributeFiltersLoading.value,
          error: attributeFiltersError.value
        });
      }
    }

    if (categories) {
      result.push({
        key: FILTER_KEYS.CATEGORIES,
        loading: categoriesLoading.value,
        error: categoriesError.value,
        label: 'Categories',
        options: categoriesRes.value,
        keyProp: 'value',
        value: convertToArray(parseQueryValue(parsedQuery[FILTER_KEYS.CATEGORIES]))
      });
    }

    if (bookable) {
      result.push({
        key: FILTER_KEYS.BOOKABLE,
        label: 'Bookable',
        options: [
          {
            id: 0,
            name: 'No'
          },
          {
            id: 1,
            name: 'Yes'
          }
        ],
        value: parseQueryValue(parsedQuery[FILTER_KEYS.BOOKABLE]) ?? ''
      });
    }

    if (registeredOnlyStats) {
      result.push({
        key: FILTER_KEYS.REGISTERED_ONLY,
        label: 'Show only registered users',
        options: [
          {
            id: 0,
            name: 'No'
          },
          {
            id: 1,
            name: 'Yes'
          }
        ],
        value: parseQueryValue(parsedQuery[FILTER_KEYS.REGISTERED_ONLY]) ?? ''
      });
    }

    if (devices) {
      result.push({
        key: FILTER_KEYS.DEVICES,
        label: 'Device type',
        options: [
          {
            id: 'desktop',
            name: 'Desktop web'
          },
          {
            id: 'mobile',
            name: 'Mobile web'
          },
          {
            id: 'app',
            name: 'Mobile app'
          }
        ],
        value: convertToArray(parseQueryValue(parsedQuery[FILTER_KEYS.DEVICES]))
      });
    }

    if (oewaUnitsOnly) {
      result.push({
        key: FILTER_KEYS.OEWA_UNITS_ONLY,
        label: 'Show only ÖWA ranking units',
        options: [
          {
            id: 0,
            name: 'No'
          },
          {
            id: 1,
            name: 'Yes'
          }
        ],
        value: parseQueryValue(parsedQuery[FILTER_KEYS.OEWA_UNITS_ONLY]) ?? ''
      });
    }

    return result;
  });

  const isAligned = computed(() => currentDataFrame.value?.is_aligned);

  const errorStatesLookup = computed(() => ({
    [FILTER_KEYS.DATA_FRAME]: dataFramesError.value,
    [FILTER_KEYS.CATEGORIES]: categoriesError.value,
    [FILTER_KEYS.ATTRIBUTES]: attributeFiltersError.value,
    [FILTER_KEYS.EXCLUDE_SERVICES]: excludableServicesError.value
  }));

  const loadingStatesLookup = computed(() => ({
    [FILTER_KEYS.DATA_FRAME]: dataFramesLoading.value || !currentDataFrame.value,
    [FILTER_KEYS.CATEGORIES]: categoriesLoading.value,
    [FILTER_KEYS.ATTRIBUTES]: attributeFiltersLoading.value,
    [FILTER_KEYS.EXCLUDE_SERVICES]: excludableServicesLoading.value
  }));

  const loadingDataFrames = computed(() => loadingStatesLookup.value[FILTER_KEYS.DATA_FRAME]);

  const fetchFilters = async () => {
    const promises = [];

    loadingFilters.value = true;

    try {
      if (dataFrames) {
        currentDataFrame.value = null;

        const dataFrames = await fetchDataFrames({
          audienceReach
        });

        const { date, interval_type, calculation_type } = parsedQuery;

        currentDataFrame.value = getCurrentDataFrame({
          date,
          interval_type,
          calculation_type,
          dataFrames
        });

        if (excludableServices) {
          promises.push(fetchExcludableServices());
        }

        if (attributes) {
          promises.push(fetchAttributeFilters());
        }
      }

      if (categories) {
        promises.push(fetchCategories());
      }

      await Promise.all(promises);
    } finally {
      loadingFilters.value = false;
    }
  };

  const dataFrameChangeHandler = dataFrame => {
    const promises = [];

    if (excludableServices) {
      promises.push(
        fetchExcludableServices({
          dataFrame
        })
      );
    }

    if (attributes) {
      promises.push(
        fetchAttributeFilters({
          dataFrame
        })
      );
    }

    return Promise.all(promises);
  };

  return {
    filters,
    isAligned,
    fetchFilters,
    loadingFilters,
    errorStatesLookup,
    loadingStatesLookup,
    loadingDataFrames,
    currentDataFrame,
    excludableServicesLoading,
    dataFrameChangeHandler
  };
};
