import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { useRoute } from 'vue-router';

export const useMediaWatchReachOfCampaigns = () => {
  const route = useRoute();

  const {
    state: { loading: fetchingReachOfCampaignsReachChartData, data: reachOfCampaignsReachChartData },
    fetcher: reachOfCampaignsReachChartDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingReachOfCampaignsTableData, data: reachOfCampaignsTableData },
    fetcher: reachOfCampaignsTableDataFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchReachOfCampaignsReachChartData = ({ defaultDate = null } = {}) =>
    reachOfCampaignsReachChartDataFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.campaign.reach-chart',
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.chart_data || {})
    });

  const fetchReachOfCampaignsTableData = ({ defaultDate = null } = {}) =>
    reachOfCampaignsTableDataFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.campaign.table',
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.table_data || {})
    });

  return {
    fetchReachOfCampaignsReachChartData,
    fetchReachOfCampaignsTableData,
    fetchingReachOfCampaignsReachChartData,
    fetchingReachOfCampaignsTableData,
    reachOfCampaignsReachChartData,
    reachOfCampaignsTableData
  };
};
