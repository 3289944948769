export const CHART_COLORS = ['#F8BB29', '#C457C5'];

export const MEDIA_WATCH_REACH_OF_BRANDS_CHART_COLORS = [
  '#f87979',
  '#7acbf9',
  '#f9d67a',
  '#7af9c1',
  '#f9a57a',
  '#7af9f1',
  '#f9e57a',
  '#7af9a1',
  '#f9a57c',
  '#7af9e1'
];
