import PrivateNotFound from 'views/404/PrivateNotFound.vue';
import OewaPublicRatingsNotFound from 'views/404/OewaPublicRatingsNotFound.vue';
import { oewaRatingsModuleRoutes, publicOewaRatingsRoutes } from './apps/oewa.js';
import { adminModuleRoutes } from './apps/management';
import { authRoutes } from './apps/auth';
import { keywordToolModuleRoutes } from './apps/keys';
import { streamingRatingsModuleRoutes } from './apps/streaming';
import { dmpFeedModuleRoutes } from './apps/dmp-feed';
import { ratingsModuleRoutes } from './apps/ratings';
import { campaignControlModuleRoutes } from './apps/campaigns';
import { adeffxModuleRoutes } from './apps/adeffx.js';
import { publisherModuleRoutes } from './apps/publishers';
import { brandliftRoutes } from './apps/brandlift';
import { socialAnalyticsRoutes } from './apps/socialAnalytics';
import { publisherDashboardModuleRoutes } from './apps/publisherDashboard';
import { mediaWatchModuleRoutes } from './apps/mediaWatch.js';
import { OEWA_BUILD, OEWA_PUBLIC_BUILD } from '../constants.js';

import AppLauncher from '../views/pages/AppLauncher.vue';
import Roundabout from '../views/pages/Roundabout.vue';
import NoPermissions from '../views/NoPermissions.vue';
import { APPS } from 'utils/constants';

const notFoundRouteBase = {
  name: 'not-found',
  path: '/:pathMatch(.*)*'
};

const privateNotFoundRoute = {
  ...notFoundRouteBase,
  component: PrivateNotFound
};

const oewaPublicRatingsNotFoundRoute = {
  ...notFoundRouteBase,
  component: OewaPublicRatingsNotFound
};

let appRoutes = [
  {
    path: '/',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: '',
        name: 'initialpage',
        component: Roundabout
      },
      {
        path: 'home',
        name: 'startpage',
        component: AppLauncher,
        meta: {
          auth: true,
          app: APPS.HOME,
          hasCountrySwitcher: true
        }
      },
      {
        path: 'no-permission',
        name: 'no-permission',
        component: NoPermissions
      }
    ]
  },
  ...authRoutes,
  ...oewaRatingsModuleRoutes,
  ...adminModuleRoutes,
  ...keywordToolModuleRoutes,
  ...streamingRatingsModuleRoutes,
  ...dmpFeedModuleRoutes,
  ...ratingsModuleRoutes,
  ...campaignControlModuleRoutes,
  ...adeffxModuleRoutes,
  ...publisherModuleRoutes,
  ...brandliftRoutes,
  ...socialAnalyticsRoutes,
  ...publisherDashboardModuleRoutes,
  ...mediaWatchModuleRoutes,
  privateNotFoundRoute
];

if ((location.hostname !== 'dashboard.oewa.at' && !!OEWA_BUILD) || !!OEWA_PUBLIC_BUILD) {
  appRoutes = [...publicOewaRatingsRoutes, oewaPublicRatingsNotFoundRoute];
}

export { appRoutes };
