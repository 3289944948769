import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { useRoute } from 'vue-router';

export const useMediaWatchReachOfBrands = () => {
  const route = useRoute();

  const {
    state: { loading: fetchingReachOfBrandsReachChartData, data: reachOfBrandsReachChartData },
    fetcher: reachOfBrandsReachChartDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingReachOfBrandsTableData, data: reachOfBrandsTableData },
    fetcher: reachOfBrandsTableDataFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingReachOfBrandsTableSubItemsData, data: reachOfBrandsTableSubItemsData },
    fetcher: reachOfBrandsTableSubItemsDataFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchReachOfBrandsReachChartData = ({ defaultDate = null } = {}) =>
    reachOfBrandsReachChartDataFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand.reach-chart',
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.chart_data || {})
    });

  const fetchReachOfBrandsTableData = ({ defaultDate = null } = {}) =>
    reachOfBrandsTableDataFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand.table',
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.table_data || {})
    });

  const fetchReachOfBrandsTableSubItemsData = ({ id, defaultDate = null } = {}) =>
    reachOfBrandsTableSubItemsDataFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand.table.sub-items',
        params: { id },
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.sub_items || {})
    });

  return {
    fetchReachOfBrandsReachChartData,
    fetchReachOfBrandsTableData,
    fetchReachOfBrandsTableSubItemsData,
    fetchingReachOfBrandsReachChartData,
    fetchingReachOfBrandsTableData,
    fetchingReachOfBrandsTableSubItemsData,
    reachOfBrandsReachChartData,
    reachOfBrandsTableData,
    reachOfBrandsTableSubItemsData
  };
};
