import { APPS } from 'utils/constants';
import { exportRoutesWithAppMetaField } from 'utils/helpers';

export const mediaWatchModuleRoutes = exportRoutesWithAppMetaField(APPS.MEDIA_WATCH, [
  {
    path: '/:country_code/media-watch',
    component: {
      render: () => <RouterView />
    },
    meta: {
      locale: 'en-US'
    },
    children: [
      {
        path: 'reach-of-brands',
        name: 'media-watch.reach-of-brands',
        component: () =>
          import(
            /* webpackChunkName: "media-watch" */ '../../views/pages/mediaWatch/MediaWatchReachOfBrands.vue'
          ),
        meta: {
          auth: true,
          module: 'media_watch',
          hasCountrySwitcher: true
        }
      },
      {
        path: 'reach-of-brands/:id/insights',
        name: 'media-watch.reach-of-brands.insights',
        component: () =>
          import(
            /* webpackChunkName: "media-watch" */ '../../views/pages/mediaWatch/MediaWatchReachOfBrandsInsights.vue'
          ),
        meta: {
          auth: true,
          module: 'media_watch',
          hasCountrySwitcher: true
        }
      },
      // {
      //   path: 'brand-comparison',
      //   name: 'media-watch.brand-comparison',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "media-watch" */ '../../views/pages/mediaWatch/MediaWatchBrandComparison.vue'
      //     ),
      //   meta: {
      //     auth: true,
      //     module: 'media_watch',
      //     hasCountrySwitcher: true
      //   }
      // },
      {
        path: 'brand-comparison/results',
        name: 'media-watch.brand-comparison.results',
        component: () =>
          import(
            /* webpackChunkName: "media-watch" */ '../../views/pages/mediaWatch/MediaWatchBrandComparisonResults.vue'
          ),
        meta: {
          auth: true,
          module: 'media_watch',
          hasCountrySwitcher: true
        }
      },
      {
        path: 'reach-of-campaigns',
        name: 'media-watch.reach-of-campaigns',
        component: () =>
          import(
            /* webpackChunkName: "media-watch" */ '../../views/pages/mediaWatch/MediaWatchReachOfCampaigns.vue'
          ),
        meta: {
          auth: true,
          module: 'media_watch',
          hasCountrySwitcher: true
        }
      },
      {
        path: 'reach-of-campaigns/:id/insights',
        name: 'media-watch.reach-of-campaigns.insights',
        component: () =>
          import(
            /* webpackChunkName: "media-watch" */ '../../views/pages/mediaWatch/MediaWatchReachOfCampaignsInsights.vue'
          ),
        meta: {
          auth: true,
          module: 'media_watch',
          hasCountrySwitcher: true
        }
      }
    ]
  }
]);
