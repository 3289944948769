export const APPROVAL_STATES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  AUTO_APPROVED: 'auto_approved',
  DECLINED: 'declined'
};

export const GENERIC_LINE_CHART_X_AXIS_OPTIONS = {
  labels: {
    formatter: function () {
      const { value, showLabel } = this.value;

      return showLabel ? value : '';
    }
  }
};

export const NOT_ENOUGH_DATA_INFO_MESSAGE = 'We don’t have enough data for this metric.';
