import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { useRoute } from 'vue-router';

export const usePublisherDashboard = () => {
  const route = useRoute();

  const {
    state: { loading: fetchingOverviewTabData, error: fetchingOverviewTabDataError, data: overviewTabData },
    fetcher: overviewTabDataFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: {
      loading: fetchingImpressionsTabData,
      error: fetchingImpressionsTabDataError,
      data: impressionsTabData
    },
    fetcher: impressionsTabDataFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingVisitsTabData, error: fetchingVisitsTabDataError, data: visitsTabData },
    fetcher: visitsTabDataFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchOverviewTabData = () =>
    overviewTabDataFetcher({
      url: getApiRoute({
        name: 'api.publisher-dashboard.overview.data',
        query: {
          ...route.query
        }
      }),
      responseAdapter: ({ data }) => data
    });

  const fetchImpressionsTabData = () =>
    impressionsTabDataFetcher({
      url: getApiRoute({
        name: 'api.publisher-dashboard.impressions.data',
        query: {
          ...route.query
        }
      }),
      responseAdapter: ({ data }) => data
    });

  const fetchVisitsTabData = () =>
    visitsTabDataFetcher({
      url: getApiRoute({
        name: 'api.publisher-dashboard.visits.data',
        query: {
          ...route.query
        }
      }),
      responseAdapter: ({ data }) => data
    });

  return {
    fetchOverviewTabData,
    fetchingOverviewTabData,
    fetchingOverviewTabDataError,
    overviewTabData,
    fetchImpressionsTabData,
    fetchingImpressionsTabData,
    fetchingImpressionsTabDataError,
    impressionsTabData,
    fetchVisitsTabData,
    fetchingVisitsTabData,
    fetchingVisitsTabDataError,
    visitsTabData
  };
};
