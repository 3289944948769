import { defineAsyncComponent } from 'vue';

export const Tooltip = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/Tooltip')
);

export const ButtonPlaceholder = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/ButtonPlaceholder')
);

export const HelperDataSource = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/HelperDataSource')
);

export const Spinner = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/Spinner')
);

export const GraphLoader = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/GraphLoader')
);

export const BarChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/BarChart')
);

export const Fetcher = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/Fetcher')
);

export const ServiceSelection = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/ServiceSelection')
);

export const Slide = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './animation/Slide')
);

export const Icon = defineAsyncComponent(() => import(/* webpackChunkName: "generic" */ './generic/Icon'));

export const GrowthTicker = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/GrowthTicker')
);

export const SwitcherContainer = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/SwitcherContainer.vue')
);

export const Button = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/Button')
);

export const TimeoutReloader = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/TimeoutReloader')
);

export const NotEnoughData = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/NotEnoughData')
);

export const ChartExport = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/ChartExport')
);

export const HelperText = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/HelperText')
);

export const ProgressBar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/ProgressBar')
);

export const Demographics = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/Demographics.vue')
);

export const DemographicsAttribute = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/partials/DemographicsAttribute.vue')
);

export const DemographicsBarCharts = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/partials/DemographicsBarCharts.vue')
);

export const NewDemographics = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/NewDemographics.vue')
);

export const DemographicsTabs = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/partials/DemographicsTabs.vue')
);

export const DemographicsCollapse = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/partials/DemographicsCollapse.vue')
);

export const NewDemographicsAttributeExport = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/partials/NewDemographicsAttributeExport.vue')
);

export const ChannelsList = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/ChannelsList.vue')
);

export const ChannelCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './shared/ChannelCreateOrEdit.vue')
);

export const ChannelCreateOrEditManageTargetUrls = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ratings-digital" */ './shared/partials/ChannelCreateOrEditManageTargetUrls.vue'
  )
);

export const RatingsWidgetAudienceReachChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/widgets/RatingsWidgetAudienceReachChart')
);

export const RatingsDetailsTrafficMovementsWidgets = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ratings-digital" */ './ratings/widgets/RatingsDetailsTrafficMovementsWidgets.vue'
  )
);

export const RatingsDetailsTrafficMovementsWidget = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ratings-digital" */ './ratings/widgets/RatingsDetailsTrafficMovementsWidget.vue'
  )
);

export const RatingsDetailsCompetitorsWidget = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/widgets/RatingsDetailsCompetitorsWidget.vue')
);

export const RatingsDetailsSubServicesWidget = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/widgets/RatingsDetailsSubServicesWidget.vue')
);

export const RatingsSubServices = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsSubServices.vue')
);

export const RatingsServicesList = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsServicesList.vue')
);

export const RatingsAudienceReachChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsAudienceReachChart')
);

export const Panel = defineAsyncComponent(() => import(/* webpackChunkName: "generic" */ './shared/Panel'));

export const ConnectedPanels = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/ConnectedPanels.vue')
);

export const UploadImage = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/UploadImage.vue')
);

export const RatingsCombineIntro = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsCombineIntro.vue')
);

export const RatingsCombineServices = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsCombineServices.vue')
);

export const AllRatingsList = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/AllRatingsList')
);

export const RatingsDetailsIntro = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsDetailsIntro.vue')
);

export const RatingsCompareOrCombineIntro = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsCompareOrCombineIntro.vue')
);

export const RatingsCompareOrCombineServiceSelect = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/RatingsCompareOrCombineServiceSelect.vue')
);

export const FiltersForm = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/FiltersForm.vue')
);

export const Breadcrumbs = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/Breadcrumbs.vue')
);

export const ExportList = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/ExportList')
);

export const ExportListButton = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/ExportListButton.vue')
);

export const CopyCode = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/CopyCode.vue')
);

export const AppLogo = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/AppLogo.vue')
);

export const TrafficStatisticsChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/TrafficStatisticsChart')
);

export const TrafficStatisticsChartWrapper = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/TrafficStatisticsChartWrapper')
);

export const VisitsTableWrapper = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/VisitsTableWrapper.vue')
);

export const ExportLayout = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/ExportLayout.vue')
);

export const ExportSlide = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/partials/ExportSlide.vue')
);

export const ExportHeader = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/partials/ExportHeader.vue')
);

export const ExportFooter = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/partials/ExportFooter.vue')
);

export const NotificationCenter = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/partials/NotificationCenter.vue')
);

export const CustomTooltip = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/partials/CustomTooltip.vue')
);

export const Sidebar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/Sidebar')
);

export const ConnectedPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/partials/ConnectedPanel')
);

export const Navigation = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/sidebar/Navigation')
);

export const CaptionBadge = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/CaptionBadge')
);

export const Badge = defineAsyncComponent(() => import(/* webpackChunkName: "generic" */ './shared/Badge'));

export const PageTitle = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/partials/PageTitle')
);

export const AppSwitcher = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/AppSwitcher.vue')
);

export const AccountSwitcher = defineAsyncComponent(() =>
  import(/* webpackChunkName: "layout" */ './layout/AccountSwitcher.vue')
);

export const RatingsDetailsTrafficStatisticsChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/RatingsDetailsTrafficStatisticsChart')
);

export const RatingsDetailsVisitsTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/RatingsDetailsVisitsTable')
);

export const RatingsWidgetSearchMetrics = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/widgets/RatingsWidgetSearchMetrics')
);

export const RatingsListItemDevice = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsListItemDevice')
);

export const RatingsListItemCol = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsListItemCol')
);

export const ServiceSelector = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/ServiceSelector')
);

export const RatingsFilters = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/filters/RatingsFilters.vue')
);

export const RatingsFiltersBar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/filters/RatingsFiltersBar.vue')
);

export const RatingsFiltersForm = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/filters/RatingsFiltersForm.vue')
);

export const RatingsDataFrameFilter = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/filters/RatingsDataFrameFilter.vue')
);

export const RatingsAddAttributeFilterModal = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ratings-digital" */ './ratings/partials/filters/RatingsAddAttributeFilterModal.vue'
  )
);

export const RatingsWidgetErrorMessage = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsWidgetErrorMessage.vue')
);

export const RatingsCompareHeader = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsCompareHeader.vue')
);

export const RatingsCompareKpis = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsCompareKpis.vue')
);

export const RatingsCompareTrafficMovementsWidgets = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsCompareTrafficMovementsWidgets.vue'
  )
);

export const RatingsCompareTrafficMovementsWidget = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsCompareTrafficMovementsWidget.vue'
  )
);

export const RatingsCompareAudience = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsCompareAudience.vue')
);

export const CampaignsList = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx-and-campaign-control" */ './shared/CampaignsList')
);

export const CampaignsListTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/CampaignsListTable')
);

export const CampaignAndFlightCreatives = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './shared/CampaignAndFlightCreatives')
);

export const CampaignAndFlightCreativesTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './shared/CampaignAndFlightCreativesTable')
);

export const AdeffxFlightAndCampaignReachChart = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/charts/AdeffxFlightAndCampaignReachChart'
  )
);

export const CampaignAdImpressionsChartPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignAdImpressionsChartPanel')
);

export const CampaignAdImpressionsChartPanelInner = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignAdImpressionsChartPanelInner')
);

export const CampaignAdImpressionsTablePanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignAdImpressionsTablePanel')
);

export const CampaignAdImpressionsTablePanelInner = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignAdImpressionsTablePanelInner')
);

export const CampaignDetailsBrandLiftPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignDetailsBrandLiftPanel.vue')
);

export const AdeffxAndCampaignDetailsReachChartPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/panels/AdeffxAndCampaignDetailsReachChartPanel'
  )
);

export const AdeffxAndCampaignInfoPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx-and-campaign-control" */ './shared/panels/AdeffxAndCampaignInfoPanel')
);

export const AdeffxAndCampaignInfoPanelInner = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/panels/AdeffxAndCampaignInfoPanelInner'
  )
);

export const AdeffxAndCampaignTargetAccuracyPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/panels/AdeffxAndCampaignTargetAccuracyPanel'
  )
);

export const AdeffxAndCampaignTargetAccuracyPanelInner = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/panels/AdeffxAndCampaignTargetAccuracyPanelInner'
  )
);

export const CampaignInsightsPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignInsightsPanel.vue')
);

export const CampaignInsightsPanelInner = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignInsightsPanelInner.vue')
);

export const CampaignUserAgentBasedDataPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignUserAgentBasedDataPanel.vue')
);

export const CampaignUserAgentBasedDataPanelInner = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "campaign-control" */ './campaigns/panels/CampaignUserAgentBasedDataPanelInner.vue'
  )
);

export const AdeffxAndCampaignDetailsPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/panels/AdeffxAndCampaignDetailsPanel'
  )
);

export const CampaignCreateOrEditBrandsList = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/CampaignCreateOrEditBrandsList.vue')
);

export const CampaignCreateOrEditSurveysManagement = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/CampaignCreateOrEditSurveysManagement.vue')
);

export const SurveyCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/SurveyCreateOrEdit.vue')
);

export const BillingWidgetCategories = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './customer/widgets/BillingWidgetCategories')
);

export const BillingWidgetBreakdown = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './customer/widgets/BillingWidgetBreakdown')
);

export const BillingWidgetAdvertisersBreakdown = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './customer/widgets/BillingWidgetAdvertisersBreakdown')
);

export const BillingWidgetCustomerAdverisersBreakdown = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './customer/widgets/BillingWidgetCustomerAdverisersBreakdown')
);

export const BillingWidgetPageImpressions = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './customer/widgets/BillingWidgetPageImpressions')
);

export const BillingWidgetAdImpressions = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './customer/widgets/BillingWidgetAdImpressions')
);

export const CampaignKPIWidgets = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/CampaignKPIWidgets')
);

export const CampaignKPIProgress = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/CampaignKPIProgress')
);

export const AdeffxAndCampaignDetailsInfo = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/partials/AdeffxAndCampaignDetailsInfo'
  )
);

export const CampaignTrafficLight = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/partials/CampaignTrafficLight')
);

export const CampaignAndFlightDetailsIntro = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './shared/partials/CampaignAndFlightDetailsIntro')
);

export const CustomerPricingItem = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './customer/CustomerPricingItem')
);

export const DiscountItem = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './customer/partials/DiscountItem')
);

export const UsersManagementList = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './user/UsersManagementList')
);

export const CampaignDetailsBreakdownChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/partials/CampaignDetailsBreakdownChart')
);

export const CampaignDetailsBreakdownHeader = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/partials/CampaignDetailsBreakdownHeader')
);

export const CampaignDetailsBreakdownTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/partials/CampaignDetailsBreakdownTable')
);

export const AdeffxAndCampaignToolbarFiltersForm = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/partials/AdeffxAndCampaignToolbarFiltersForm'
  )
);

export const AdeffxAndCampaignToolbar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx-and-campaign-control" */ './shared/partials/AdeffxAndCampaignToolbar')
);

export const AdeffxAndCampaignCreateOrEditHeader = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/partials/AdeffxAndCampaignCreateOrEditHeader'
  )
);

export const AdeffxAndCampaignTooltip = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx-and-campaign-control" */ './shared/partials/AdeffxAndCampaignTooltip')
);

export const AdeffxTooltip = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/partials/AdeffxTooltip.vue')
);

export const AdeffxProgressSticky = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/partials/AdeffxProgressSticky.vue')
);

export const AdeffxFlightCreateOrEditBasicInformationPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/AdeffxFlightCreateOrEditBasicInformationPanel.vue')
);

export const CampaignCreateOrEditTrackingPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './shared/partials/CampaignCreateOrEditTrackingPanel')
);

export const AdeffxAndCampaignCreateOrEditTargetAudiencePanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "adeffx-and-campaign-control" */ './shared/partials/AdeffxAndCampaignCreateOrEditTargetAudiencePanel'
  )
);

export const AdeffxFlightSurveyIntentionPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/panels/AdeffxFlightSurveyIntentionPanel.vue')
);

export const AdeffxCampaignsListTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/tables/AdeffxCampaignsListTable')
);

export const AdeffxFlightSurveyLinksPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/panels/AdeffxFlightSurveyLinksPanel.vue')
);

export const AdeffxFlightSurveyCompletesPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/panels/AdeffxFlightSurveyCompletesPanel.vue')
);

export const AdeffxFlightSurveyGenderPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/panels/AdeffxFlightSurveyGenderPanel.vue')
);

export const AdeffxFlightSurveyRecallAndRecognitionPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/panels/AdeffxFlightSurveyRecallAndRecognitionPanel.vue')
);

export const AdeffxFlightSurveyAgePanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/panels/AdeffxFlightSurveyAgePanel.vue')
);

export const RatingsDetailsChartHeader = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './ratings/partials/RatingsDetailsChartHeader.vue')
);

export const AttributeSearch = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/AttributeSearch.vue')
);

export const FiltersBar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/FiltersBar.vue')
);

export const NewFiltersBar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/NewFiltersBar.vue')
);

export const FiltersClearButton = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/FiltersClearButton.vue')
);

export const FiltersToolbar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/FiltersToolbar.vue')
);

export const RightsManagementTree = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/shared/RightsManagementTree')
);

export const UserForm = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/shared/UserForm')
);

export const AdminCustomers = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './management/AdminCustomers.vue')
);

export const ChannelCustomers = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './management/ChannelCustomers.vue')
);

export const ChannelTypesManagementCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/ChannelTypesManagementCreateOrEdit.vue')
);

export const LocalesManagementForm = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/LocalesManagementForm')
);

export const TranslationsManagementForm = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/TranslationsManagementForm')
);

export const TranslationsManagementLocaleSelector = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/TranslationsManagementLocaleSelector')
);

export const TagsManagementForm = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/TagsManagementForm')
);

export const OewaCustomersDropdown = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/OewaCustomersDropdown.vue')
);

export const ManagementItemsList = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/ManagementItemsList.vue')
);

export const ManagementHeader = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/ManagementHeader.vue')
);

export const OewaMediaHouseOrAdNetworkCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/OewaMediaHouseOrAdNetworkCreateOrEdit.vue')
);

export const OewaServiceCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/OewaServiceCreateOrEdit.vue')
);

export const OewaTrackingIdentifierCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/OewaTrackingIdentifierCreateOrEdit.vue')
);

export const BrandCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/BrandCreateOrEdit.vue')
);

export const SurveyTemplateCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/SurveyTemplateCreateOrEdit.vue')
);

export const SurveyTemplateImport = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/SurveyTemplateImport.vue')
);

export const EnabledFromAndUntilRangePicker = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/partials/EnabledFromAndUntilRangePicker.vue')
);

export const AdNetworksOrMediaHousesServicesOrGroupsManagement = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/AdNetworksOrMediaHousesServicesOrGroupsManagement.vue')
);

export const CustomerCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/CustomerCreateOrEdit.vue')
);

export const AdNetworkOrMediaHouseServicesAndGroupsClearModal = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "generic" */ './management/partials/AdNetworkOrMediaHouseServicesAndGroupsClearModal.vue'
  )
);

export const ModulesManagementCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/ModulesManagementCreateOrEdit.vue')
);

export const CustomerReadonlyData = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './customer/partials/CustomerReadonlyData.vue')
);

export const OewaChannelTrackingIdentifiersAndPixelPathsConfigurationModal = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "oewa_ratings" */ './oewa/OewaChannelTrackingIdentifiersAndPixelPathsConfigurationModal.vue'
  )
);

export const OewaChannelCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaChannelCreateOrEdit.vue')
);

export const OewaChannelHistoricView = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaChannelHistoricView.vue')
);

export const OewaChannelTrackingDefinitionsTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaChannelTrackingDefinitionsTable.vue')
);

export const OewaChannelsOverview = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaChannelsOverview.vue')
);

export const OewaChannelsOverviewTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaChannelsOverviewTable.vue')
);

export const OewaChannelsOverviewActiveChannels = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaChannelsOverviewActiveChannels.vue')
);

export const OewaChannelsOverviewHistoricChannels = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaChannelsOverviewHistoricChannels.vue')
);

export const OewaChannelTrackingIdentifiersAndPixelPathsConfigurationTable = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaChannelTrackingIdentifiersAndPixelPathsConfigurationTable.vue'
  )
);

export const OewaRatings = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatings.vue')
);

export const OewaRatingsLayout = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/layouts/OewaRatingsLayout.vue')
);

export const OewaPublicRatings = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaPublicRatings.vue')
);

export const OewaRatingsTabs = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsTabs.vue')
);

export const OewaRatingsInfoPopover = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaRatingsInfoPopover.vue')
);

export const OewaRatingsTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsTable.vue')
);

export const OewaRatingsToolbar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsToolbar.vue')
);

export const OewaRatingsFiltersBar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaRatingsFiltersBar.vue')
);

export const OewaRatingsFiltersForm = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaRatingsFiltersForm.vue')
);

export const OewaRatingsSingleSelectFilter = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaRatingsSingleSelectFilter.vue')
);

export const OewaRatingsDetails = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsDetails.vue')
);

export const OewaRatingsDetailsVisitsTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsDetailsVisitsTable.vue')
);

export const OewaRatingsDetailsDemographics = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsDetailsDemographics.vue')
);

export const OewaDemographicsExport = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaDemographicsExport.vue')
);

export const OewaRatingsDetailsIntro = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsDetailsIntro.vue')
);

export const OewaRatingsDetailsZerviceAction = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaRatingsDetailsZerviceAction.vue')
);

export const OewaRatingsDetailsAdminAction = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaRatingsDetailsAdminAction.vue')
);

export const OewaRatingsDetailsOwnerAction = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/partials/OewaRatingsDetailsOwnerAction.vue')
);

export const OewaRatingsInternetPopulation = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsInternetPopulation.vue')
);

export const OewaRatingsDetailsAppAndWebLocals = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsDetailsAppAndWebLocals.vue')
);

export const OewaRatingsDetailsTrafficStatistics = defineAsyncComponent(() =>
  import(/* webpackChunkName: "oewa_ratings" */ './oewa/OewaRatingsDetailsTrafficStatistics.vue')
);
export const CompanyCreateOrEdit = defineAsyncComponent(() =>
  import(/* webpackChunkName: "admin" */ './management/CompanyCreateOrEdit.vue')
);

export const CampaignTooltip = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/partials/CampaignTooltip.vue')
);

export const CampaignCreateOrEditBasicInformationPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "campaign-control" */ './campaigns/partials/create-or-edit/CampaignCreateOrEditBasicInformationPanel.vue'
  )
);

export const CampaignAttributeInput = defineAsyncComponent(() =>
  import(/* webpackChunkName: "campaign-control" */ './campaigns/partials/CampaignAttributeInput.vue')
);

export const CampaignCreateOrEditBrandliftPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "campaign-control" */ './campaigns/partials/create-or-edit/CampaignCreateOrEditBrandliftPanel.vue'
  )
);

export const CampaignCreateNewAdvertiserModal = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "campaign-control" */ './campaigns/partials/CampaignCreateNewAdvertiserModal.vue'
  )
);

export const CampaignContactsBreakdownLevels = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "campaign-control" */ './campaigns/partials/CampaignContactsBreakdownLevels.vue'
  )
);

export const AdeffxSurveyCompletesTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/tables/AdeffxSurveyCompletesTable.vue')
);

export const AdeffxSurveyGenderTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/tables/AdeffxSurveyGenderTable.vue')
);

export const AdeffxFlightSurveyAgeCharts = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/charts/AdeffxFlightSurveyAgeCharts.vue')
);

export const AdeffxFlightSurveyRecallAndRecognitionCharts = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/charts/AdeffxFlightSurveyRecallAndRecognitionCharts.vue')
);

export const AdeffxFlightSurveyIntentionCharts = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/charts/AdeffxFlightSurveyIntentionCharts.vue')
);

export const AdeffxFlightSurveyAgeChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/charts/AdeffxFlightSurveyAgeChart.vue')
);

export const AdeffxFlightSurveyRecallAndRecognitionChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/charts/AdeffxFlightSurveyRecallAndRecognitionChart.vue')
);

export const TargetsAssignTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './pages/management/TargetsAssignTable.vue')
);

export const TargetsAssignToolbar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "ratings-digital" */ './management/partials/TargetsAssignToolbar.vue')
);

export const TargetsAssignToolbarFiltersForm = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ratings-digital" */ './management/partials/TargetsAssignToolbarFiltersForm.vue'
  )
);

export const TargetsAssignToolbarFiltersFormItem = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ratings-digital" */ './management/partials/TargetsAssignToolbarFiltersFormItem'
  )
);

export const ToolbarFiltersFormItem = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/partials/ToolbarFiltersFormItem')
);

export const AdeffxSurveyInsightsTable = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/tables/AdeffxSurveyInsightsTable.vue')
);

export const AdeffxFlightInsightsPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/panels/AdeffxFlightInsightsPanel.vue')
);

export const AdeffxFlightSurveyQuestionsPanel = defineAsyncComponent(() =>
  import(/* webpackChunkName: "adeffx" */ './adeffx/panels/AdeffxFlightSurveyQuestionsPanel.vue')
);

export const ServicesCreateOrEditMainPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "admin" */ './pages/management/services/create_or_edit/ServicesCreateOrEditMainPanel.vue'
  )
);

export const ServicesCreateOrEditSecondaryPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "admin" */ './pages/management/services/create_or_edit/ServicesCreateOrEditSecondaryPanel.vue'
  )
);

export const ServicesManageTargetsRegularExpressions = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "admin" */ './pages/management/services/partials/ServicesManageTargetsRegularExpressions.vue'
  )
);

export const DashboardToolbar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/partials/DashboardToolbar.vue')
);

export const PublisherDashboardToolbar = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "publisher-dashboard" */ './pages/publisherDashboard/partials/PublisherDashboardToolbar.vue'
  )
);

export const PublisherDashboardToolbarFiltersForm = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "publisher-dashboard" */ './pages/publisherDashboard/partials/PublisherDashboardToolbarFiltersForm.vue'
  )
);

export const PublisherDashboardOverviewTable = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "publisher-dashboard" */ './pages/publisherDashboard/tables/PublisherDashboardOverviewTable.vue'
  )
);

export const PublisherDashboardImpressionsTable = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "publisher-dashboard" */ './pages/publisherDashboard/tables/PublisherDashboardImpressionsTable.vue'
  )
);

export const PublisherDashboardVisitsTable = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "publisher-dashboard" */ './pages/publisherDashboard/tables/PublisherDashboardVisitsTable.vue'
  )
);

export const PublisherDashboardImpressionsOrVisitsChart = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "publisher-dashboard" */ './pages/publisherDashboard/charts/PublisherDashboardImpressionsOrVisitsChart.vue'
  )
);

export const PublisherDashboardKpiPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "publisher-dashboard" */ './pages/publisherDashboard/partials/PublisherDashboardKpiPanel.vue'
  )
);

export const ButtonSwitcher = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './generic/ButtonSwitcher.vue')
);
export const PublishersOverviewTable = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "publisher-live-view" */ './pages/publishers/tables/PublishersOverviewTable.vue'
  )
);

export const MediaWatchToolbar = defineAsyncComponent(() =>
  import(/* webpackChunkName: "media-watch" */ './pages/mediaWatch/partials/MediaWatchToolbar.vue')
);

export const MediaWatchToolbarFiltersForm = defineAsyncComponent(() =>
  import(/* webpackChunkName: "media-watch" */ './pages/mediaWatch/partials/MediaWatchToolbarFiltersForm.vue')
);

export const TopItemsSelector = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/partials/generic/TopItemsSelector.vue')
);

export const LineChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/charts/generic/LineChart.vue')
);

export const MediaWatchReachOfBrandsOrCampaignsTable = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "media-watch" */ './pages/mediaWatch/tables/MediaWatchReachOfBrandsOrCampaignsTable.vue'
  )
);

export const ExpandableWrapper = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/ExpandableWrapper.vue')
);

export const StackedRowWidget = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/widgets/generic/StackedRowWidget.vue')
);

export const EstimatedAdImpressionsWidget = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/widgets/EstimatedAdImpressionsWidget.vue')
);

export const MaximumPotentialUserReachWidget = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/widgets/MaximumPotentialUserReachWidget.vue')
);

export const WidgetWrapper = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/widgets/generic/WidgetWrapper.vue')
);

export const VerticalBarWidget = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/widgets/generic/VerticalBarWidget.vue')
);

export const DonutProgressWidget = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/widgets/generic/DonutProgressWidget.vue')
);

export const AreaSplineChartWidget = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/widgets/generic/AreaSplineChartWidget.vue')
);

export const NoData = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/partials/generic/NoData.vue')
);

export const MediaWatchBrandComparisonFilterFormPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "media-watch" */ './pages/mediaWatch/panels/MediaWatchBrandComparisonFilterFormPanel.vue'
  )
);

export const MediaWatchBrandComparisonResultsPanel = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "media-watch" */ './pages/mediaWatch/panels/MediaWatchBrandComparisonResultsPanel.vue'
  )
);

export const MediaWatchToolbarFiltersFormItem = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "media-watch" */ './pages/mediaWatch/partials/MediaWatchToolbarFiltersFormItem.vue'
  )
);

export const FiltersFormNew = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/FiltersFormNew.vue')
);

export const ToolbarFiltersFormItemNew = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/partials/ToolbarFiltersFormItemNew.vue')
);

export const MediaWatchLineChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "media-watch" */ './pages/mediaWatch/charts/MediaWatchLineChart.vue')
);

export const Tabs = defineAsyncComponent(() =>
  import(/* webpackChunkName: "generic" */ './shared/partials/generic/Tabs.vue')
);

export const MediaWatchStackedBarChart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "media-watch" */ './pages/mediaWatch/charts/MediaWatchStackedBarChart.vue')
);
