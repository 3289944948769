import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

export const useMediaWatch = () => {
  const {
    state: { loading: fetchingLatestPublishedDate, data: latestPublishedDate },
    fetcher: latestPublishedDateFetcher
  } = useAxios({
    initialValue: {}
  });

  const fetchLatestPublishedDate = () =>
    latestPublishedDateFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.latest-published-date'
      }),
      responseAdapter: resp => resp?.data?.date || ''
    });

  return {
    fetchLatestPublishedDate,
    fetchingLatestPublishedDate,
    latestPublishedDate
  };
};
