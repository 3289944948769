import { APPS } from 'utils/constants';
import { exportRoutesWithAppMetaField } from 'utils/helpers';

export const publisherDashboardModuleRoutes = exportRoutesWithAppMetaField(APPS.PUBLISHER_DASHBOARD, [
  {
    path: '/:country_code/publisher-dashboard',
    component: {
      render: () => <RouterView />
    },
    meta: {
      locale: 'en-US'
    },
    children: [
      {
        path: '',
        name: 'publisher-dashboard.overview',
        component: () =>
          import(
            /* webpackChunkName: "publisher-dashboard" */ '../../views/pages/publisherDashboard/PublisherDashboardOverview.vue'
          ),
        meta: {
          auth: true,
          module: 'publisher_dashboard',
          hasCountrySwitcher: true
        }
      },
      {
        path: 'overview/export',
        name: 'publisher-dashboard.overview.export',
        component: () =>
          import(
            /* webpackChunkName: "publisher-dashboard" */ '../../views/pages/publisherDashboard/PublisherDashboardOverviewExport.vue'
          ),
        meta: {
          auth: true,
          module: 'publisher_dashboard'
        }
      },
      {
        path: 'impressions/export',
        name: 'publisher-dashboard.impressions.export',
        component: () =>
          import(
            /* webpackChunkName: "publisher-dashboard" */ '../../views/pages/publisherDashboard/PublisherDashboardImpressionsExport.vue'
          ),
        meta: {
          auth: true,
          module: 'publisher_dashboard'
        }
      },
      {
        path: 'visits/export',
        name: 'publisher-dashboard.visits.export',
        component: () =>
          import(
            /* webpackChunkName: "publisher-dashboard" */ '../../views/pages/publisherDashboard/PublisherDashboardVisitsExport.vue'
          ),
        meta: {
          auth: true,
          module: 'publisher_dashboard'
        }
      }
    ]
  }
]);
