import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { useRoute } from 'vue-router';

export const useMediaWatchBrandComparison = () => {
  const route = useRoute();

  const {
    state: { loading: fetchingUniqueUsersChart, data: uniqueUsersChartData },
    fetcher: uniqueUsersChartFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingEstimatedAdImpressionsChart, data: estimatedAdImpressionsChartData },
    fetcher: estimatedAdImpressionsChartFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingOtsTotalChart, data: otsTotalChartData },
    fetcher: otsTotalChartFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingAgeGroupsData, data: ageGroupsData },
    fetcher: ageGroupsDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingGenderData, data: genderData },
    fetcher: genderDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { laoding: fetchingPlatformData, data: platformData },
    fetcher: platformDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const fetchUniqueUsersChart = () =>
    uniqueUsersChartFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand-comparison.unique-users',
        query: route.query
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.chart_data || {})
    });

  const fetchEstimatedAdImpressionsChart = () =>
    estimatedAdImpressionsChartFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand-comparison.estimated-ad-impressions',
        query: route.query
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.chart_data || {})
    });

  const fetchOtsTotalChart = () =>
    otsTotalChartFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand-comparison.ots-total',
        query: route.query
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.chart_data || {})
    });

  const fetchAgeGroupsData = () =>
    ageGroupsDataFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand-comparison.age-groups',
        query: route.query
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.chart_data || {})
    });

  const fetchGenderData = () =>
    genderDataFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand-comparison.gender',
        query: route.query
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.chart_data || {})
    });

  const fetchPlatformData = () =>
    platformDataFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand-comparison.platform',
        query: route.query
      }),
      responseAdapter: resp => (!!resp?.data?.no_data ? resp?.data : resp?.data?.chart_data || {})
    });

  return {
    fetchUniqueUsersChart,
    fetchEstimatedAdImpressionsChart,
    fetchOtsTotalChart,
    fetchAgeGroupsData,
    fetchGenderData,
    fetchPlatformData,
    fetchingUniqueUsersChart,
    fetchingEstimatedAdImpressionsChart,
    fetchingOtsTotalChart,
    fetchingAgeGroupsData,
    fetchingGenderData,
    fetchingPlatformData,
    uniqueUsersChartData,
    estimatedAdImpressionsChartData,
    otsTotalChartData,
    ageGroupsData,
    genderData,
    platformData
  };
};
