import useAxios from 'composables/useAxios';
import router, { getApiRoute } from 'routeconfig';
import { applyDefaultFilterValuesBasedOnQueryParams } from 'helper';

export const useMediaWatchFilters = () => {
  const {
    state: { loading: fetchingReachOfBrandsFilters, data: reachOfBrandsFilters },
    fetcher: reachOfBrandsFiltersFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingReachOfCampaignsFilters, data: reachOfCampaignsFilters },
    fetcher: reachOfCampaignsFiltersFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingBrandComparisonInitialFilters, data: brandComparisonInitialFilters },
    fetcher: brandComparisonInitialFiltersFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingBrandComparisonFilters, data: brandComparisonFilters },
    fetcher: brandComparisonFiltersFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingBrandOrCampaignInsightsFilters, data: brandOrCampaignInsightsFilters },
    fetcher: brandOrCampaignInsightsFiltersFetcher
  } = useAxios({
    initialValue: []
  });

  const fetchReachOfBrandsFilters = () =>
    reachOfBrandsFiltersFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand.overview.filters'
      }),
      responseAdapter: data =>
        data?.filters
          ? applyDefaultFilterValuesBasedOnQueryParams({
              filters: data.filters,
              queryParams: router.currentRoute.value.query
            })
          : []
    });

  const fetchReachOfCampaignsFilters = () =>
    reachOfCampaignsFiltersFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.campaign.overview.filters'
      }),
      responseAdapter: data =>
        data?.filters
          ? applyDefaultFilterValuesBasedOnQueryParams({
              filters: data.filters,
              queryParams: router.currentRoute.value.query
            })
          : []
    });

  const fetchBrandComparisonInitialFilters = () =>
    brandComparisonInitialFiltersFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand-comparison.initial-filters'
      }),
      responseAdapter: resp =>
        resp?.data?.filters
          ? applyDefaultFilterValuesBasedOnQueryParams({
              filters: resp.data.filters
            })
          : []
    });

  const fetchBrandComparisonFilters = () =>
    brandComparisonFiltersFetcher({
      url: getApiRoute({
        name: 'api.mediawatch.brand-comparison.filters'
      }),
      responseAdapter: resp =>
        resp?.data?.filters
          ? applyDefaultFilterValuesBasedOnQueryParams({
              filters: resp?.data?.filters,
              queryParams: router.currentRoute.value.query
            })
          : []
    });

  const fetchBrandOrCampaignInsightsFilters = ({ id, campaign = false } = {}) =>
    brandOrCampaignInsightsFiltersFetcher({
      url: getApiRoute({
        name: `api.mediawatch.${campaign ? 'campaign' : 'brand'}.insights.filters`,
        params: { id }
      }),
      responseAdapter: data =>
        data?.filters
          ? applyDefaultFilterValuesBasedOnQueryParams({
              filters: data.filters,
              queryParams: router.currentRoute.value.query
            })
          : []
    });

  return {
    fetchReachOfBrandsFilters,
    fetchReachOfCampaignsFilters,
    fetchBrandComparisonInitialFilters,
    fetchBrandComparisonFilters,
    fetchBrandOrCampaignInsightsFilters,
    fetchingReachOfBrandsFilters,
    fetchingReachOfCampaignsFilters,
    fetchingBrandComparisonInitialFilters,
    fetchingBrandComparisonFilters,
    fetchingBrandOrCampaignInsightsFilters,
    reachOfBrandsFilters,
    reachOfCampaignsFilters,
    brandComparisonInitialFilters,
    brandComparisonFilters,
    brandOrCampaignInsightsFilters
  };
};
