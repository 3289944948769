import useAxios from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';
import { useRoute } from 'vue-router';

export const useMediaWatchBrandOrCampaignInsights = () => {
  const route = useRoute();

  const {
    state: { loading: fetchingBrandOrCampaignInsightsData, data: brandOrCampaignInsightsData },
    fetcher: brandOrCampaignInsightsDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingBrandOrCampaignInsightsAgeData, data: brandOrCampaignInsightsAgeData },
    fetcher: brandOrCampaignInsightsAgeDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingBrandOrCampaignInsightsGenderData, data: brandOrCampaignInsightsGenderData },
    fetcher: brandOrCampaignInsightsGenderDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: {
      loading: fetchingBrandOrCampaignInsightsBrandReachData,
      data: brandOrCampaignInsightsBrandReachData
    },
    fetcher: brandOrCampaignInsightsBrandReachDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: {
      loading: fetchingBrandOrCampaignInsightsUserReachData,
      data: brandOrCampaignInsightsUserReachData
    },
    fetcher: brandOrCampaignInsightsUserReachDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: {
      loading: fetchingBrandOrCampaignInsightsImpressionsData,
      data: brandOrCampaignInsightsImpressionsData
    },
    fetcher: brandOrCampaignInsightsImpressionsDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const fetchBrandOrCampaignInsightsData = ({ id, campaign = false, defaultDate = null } = {}) =>
    brandOrCampaignInsightsDataFetcher({
      url: getApiRoute({
        name: `api.mediawatch.${campaign ? 'campaign' : 'brand'}.insights.data`,
        params: { id },
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: data => data?.data || {}
    });

  const fetchBrandOrCampaignInsightsAgeData = ({ id, campaign = false, defaultDate = null } = {}) =>
    brandOrCampaignInsightsAgeDataFetcher({
      url: getApiRoute({
        name: `api.mediawatch.${campaign ? 'campaign' : 'brand'}.insights.age.data`,
        params: { id },
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: data => data?.data || {}
    });

  const fetchBrandOrCampaignInsightsGenderData = ({ id, campaign = false, defaultDate = null } = {}) =>
    brandOrCampaignInsightsGenderDataFetcher({
      url: getApiRoute({
        name: `api.mediawatch.${campaign ? 'campaign' : 'brand'}.insights.gender.data`,
        params: { id },
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: data => data?.data || {}
    });

  const fetchBrandOrCampaignInsightsBrandReachData = ({ id, campaign = false, defaultDate = null } = {}) =>
    brandOrCampaignInsightsBrandReachDataFetcher({
      url: getApiRoute({
        name: `api.mediawatch.${campaign ? 'campaign' : 'brand'}.insights.brand-reach.data`,
        params: { id },
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: data => data?.data || {}
    });

  const fetchBrandOrCampaignInsightsUserReachData = ({ id, campaign = false, defaultDate = null } = {}) =>
    brandOrCampaignInsightsUserReachDataFetcher({
      url: getApiRoute({
        name: `api.mediawatch.${campaign ? 'campaign' : 'brand'}.insights.user-reach.data`,
        params: { id },
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: data => data?.data || {}
    });

  const fetchBrandOrCampaignInsightsImpressionsData = ({ id, campaign = false, defaultDate = null } = {}) =>
    brandOrCampaignInsightsImpressionsDataFetcher({
      url: getApiRoute({
        name: `api.mediawatch.${campaign ? 'campaign' : 'brand'}.insights.impressions.data`,
        params: { id },
        query: {
          ...route.query,
          ...(defaultDate && { date: defaultDate })
        }
      }),
      responseAdapter: data => data?.data || {}
    });

  return {
    fetchingBrandOrCampaignInsightsData,
    brandOrCampaignInsightsData,
    fetchBrandOrCampaignInsightsData,
    fetchingBrandOrCampaignInsightsAgeData,
    brandOrCampaignInsightsAgeData,
    fetchBrandOrCampaignInsightsAgeData,
    fetchingBrandOrCampaignInsightsGenderData,
    brandOrCampaignInsightsGenderData,
    fetchBrandOrCampaignInsightsGenderData,
    fetchingBrandOrCampaignInsightsBrandReachData,
    brandOrCampaignInsightsBrandReachData,
    fetchBrandOrCampaignInsightsBrandReachData,
    fetchingBrandOrCampaignInsightsUserReachData,
    brandOrCampaignInsightsUserReachData,
    fetchBrandOrCampaignInsightsUserReachData,
    fetchingBrandOrCampaignInsightsImpressionsData,
    brandOrCampaignInsightsImpressionsData,
    fetchBrandOrCampaignInsightsImpressionsData
  };
};
