import useAxios, { API_METHODS } from 'composables/useAxios';
import { getApiRoute } from 'routeconfig';

export const useManageTrackingIdentifiers = () => {
  const {
    state: {
      loading: fetchingTrackingIdentifiers,
      error: fetchingTrackingIdentifiersError,
      data: trackingIdentifiers
    },
    fetcher: trackingIdentifiersFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: creatingTrackingIdentifier, error: creatingTrackingIdentifierError },
    fetcher: createTrackingIdentifierFetcher
  } = useAxios();

  const {
    state: { loading: updatingTrackingIdentifier, error: updatingTrackingIdentifierError },
    fetcher: updateTrackingIdentifierFetcher
  } = useAxios();

  const {
    state: { loading: fetchingTrackingIdentifier, error: fetchingTrackingIdentifierError },
    fetcher: fetchTrackingIdentifierFetcher
  } = useAxios();

  const fetchTrackingIdentifiers = ({ customerId = null, unassigned = false } = {}) =>
    trackingIdentifiersFetcher({
      url: getApiRoute({
        name: 'api.publisher-dashboard.admin.tracking-identifiers.data',
        query: {
          ...(customerId && unassigned
            ? { unassigned_and_customer_id: customerId }
            : customerId
            ? { customer_id: customerId }
            : {})
        }
      }),
      responseAdapter: ({ tracking_identifiers }) => tracking_identifiers
    });

  const createTrackingIdentifier = ({ serviceId, customerId }) =>
    createTrackingIdentifierFetcher({
      url: getApiRoute({
        name: 'api.publisher-dashboard.admin.tracking-identifiers.create'
      }),
      method: API_METHODS.POST,
      requestBody: { service_id: serviceId, customer_id: customerId }
    });

  const updateTrackingIdentifier = ({ id, serviceId, customerId }) =>
    updateTrackingIdentifierFetcher({
      url: getApiRoute({
        name: 'api.publisher-dashboard.admin.tracking-identifiers.update',
        params: { id }
      }),
      method: API_METHODS.POST,
      requestBody: { service_id: serviceId, customer_id: customerId }
    });

  const fetchTrackingIdentifier = id =>
    fetchTrackingIdentifierFetcher({
      url: getApiRoute({
        name: 'api.publisher-dashboard.admin.tracking-identifiers.get',
        params: { id }
      }),
      responseAdapter: ({ tracking_identifier }) => tracking_identifier
    });

  return {
    fetchTrackingIdentifiers,
    fetchingTrackingIdentifiers,
    fetchingTrackingIdentifiersError,
    trackingIdentifiers,
    createTrackingIdentifier,
    creatingTrackingIdentifier,
    creatingTrackingIdentifierError,
    updateTrackingIdentifier,
    updatingTrackingIdentifier,
    updatingTrackingIdentifierError,
    fetchTrackingIdentifier,
    fetchingTrackingIdentifier,
    fetchingTrackingIdentifierError
  };
};
